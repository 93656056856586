<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="auto" md="6">
        <v-row justify="center" align="center">
          <v-col cols="12">
            <div class="display-3 font-weight-bold blue--text text-center">
              BIENVENIDO
            </div>
          </v-col>
          <v-col cols="12">
            <h2 class="text-center">AL SISTEMA DE FACTURACIÓN ELECTRÓNICA</h2>
          </v-col>
          <v-col cols="12">
            <div class="subtitle-1 text-center">
              SE PARTE DE LAS PRIMERAS EMPRESAS EN EL SALVADOR EN REALIZAR
              FACTURAS ELETRÓNICAS
            </div>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              block
              rounded
              @click="listarEmpresas()"
              :loading="loading"
              >Empresas</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" md="6">
        <v-img :src="require('@/assets/modelo_1.jpg')" width="500" />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_emp" width="500" persistent scrollable>
      <v-card>
        <v-toolbar color="light-blue" dark>
          <v-toolbar-title class="black--text"
            >[Doble click para seleccionar]</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="black" @click="dialog_emp = !dialog_emp">
            <v-icon size="30">fa-regular fa-circle-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list nav>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in items_emp"
                :key="i"
                @dblclick="selecEmp(item)"
              >
                <v-list-item-icon>
                  <v-icon> fa-solid fa-certificate </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.nombre_empresa
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog_emp: false,
    items_emp: [],
    emp_selected: "",
    loading: false,
  }),
  created() {
    if (!this.$store.state.emp) {
      this.listarEmpresas();
    }
  },
  methods: {
    listarEmpresas() {
      this.loading = true;
      axios
        .get(
          "api/UsuariosXEmpresas/ListarSinLogo/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          //console.log(response.data);
          this.dialog_emp = true;
          this.loading = false;
          this.items_emp = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selecEmp(item) {
      this.dialog_emp = false;
      axios
        .put("api/Usuarios/ActualizarEmpSeleccionada", {
          id_usuario: this.$store.state.usuario.idUsuario,
          emp_seleccionada: item.id_empresa,
        })
        .then(() => {
          localStorage.setItem("cod_emp", item.cod_empresa);
          this.$store.dispatch("guardarEmpresa", item);
        });
    },
  },
};
</script>